import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom"; // new import
import "./assets/fontawesome/css/all.css";
import "./assets/fontawesome/css/fontawesome_6.0.css";
import './index.scss';
import "./assets/txt/i18n.js";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  < BrowserRouter >
    <App />
  </ BrowserRouter >
);
