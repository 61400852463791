import React, { Suspense, useEffect, lazy, } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import './App.scss';

import { post_log } from './assets/api/post_log.js';

const HOME = lazy(() => import("./views/home/home.js"));
const TOOLS = lazy(() => import("./views/tools/tools.js"));
const CASES = lazy(() => import("./views/casestudies/casestudies.js"));
const ABOUT = lazy(() => import("./views/about/about.js"));
const E404 = lazy(() => import("./views/e404/e404.js"));
const Footer = lazy(() => import("./components/footer/footer.js"));

export default function App() {

  // Run ONCE at start
  useEffect(() => {
    // Log event
    const send_log = async () => {
      await post_log('withinSigma');
    }
    send_log();
  }, []);


  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route path="/" element={<HOME />} />
        <Route path="/tools" element={<TOOLS />} />
        <Route path="/casestudies" element={<CASES />} />
        <Route path="/about" element={<ABOUT />} />
        <Route path="/contact" element={<HOME />} />
        <Route path="*" element={<E404 />} />
      </Routes>
      <Footer />
      <ScrollToTop smooth color='#073763' />
    </Suspense>
  );
}