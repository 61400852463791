import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // language resources
        resources: {
            en: {
                translation: {
                    menu: [
                        { title: "Home", id: "#home", link: '/' },
                        { title: "Tools", id: "#tools", link: '/tools' },
                        { title: "Case Studies", id: "#casestudies", link: '/casestudies' },
                        { title: "About", id: "#about", link: '/about' },
                        { title: "Contact", id: "#contact", link: '/#contact' },
                    ],
                    e404: {
                        title: 'Oopsie!',
                        message: 'The page you are looking for does not exist. You can click the button below to go back to the homepage.',
                        button: 'Home',
                        link: '/',
                    },
                    home: {
                        title: 'Innovation Strategy',
                        message: 'Give individuals and companies a competitive edge by driving long-term success through purposeful and efficient innovations that align with their business vision.'
                    },
                    services: {
                        id: 'id_services',
                        about: {
                            title: "Responsibilities",
                            description: "Most organizations recognize the need to innovate but many struggle to build a strategy around it. Organizational myopia or corporate tunnel vision are common explanations. Hiring an expert in Innovation Strategy helps clarify the processes, benefits, and challenges of implementing strategic innovations within an organization.",
                        },
                        content: [
                            {
                                icon: 'fa-solid fa-magnifying-glass',
                                title: 'Initial Assessment',
                                content: "Evaluate the current state of the organization and establish its SWOT.",
                                bg_color: "#28a745",
                            },
                            {
                                icon: 'fa-solid fa-users',
                                title: 'Stakeholder',
                                content: "Understand stakeholders' perspectives, gather insights, and build support for the innovation initiative.",
                                bg_color: "#1677ff",
                            },
                            {
                                icon: 'fa-solid fa-chart-line',
                                title: "Market Analysis",
                                content: "Conduct research on market trends, emerging technologies, and competitive landscapes.",
                                bg_color: "#BE398D",
                            },
                            {
                                icon: 'fa-solid fa-brain',
                                title: "Ideation Development",
                                content: "Brainstorm innovative ideas and develop viable concepts that align with the organization's goals.",
                                bg_color: "#ec661f",
                            },
                            {
                                icon: 'fa-solid fa-bullseye',
                                title: "Strategy Formulation",
                                content: "Lay down an innovation strategy: objectives, key performance indicators, resource allocation, and timeline.",
                                bg_color: "#000080",
                            },
                            {
                                icon: 'fa-solid fa-tools',
                                title: 'Implementation ',
                                content: "Assist in executing the strategy and providing ongoing support.",
                                bg_color: "#6b53ab",
                            },
                            {
                                icon: 'fa-solid fa-eye',
                                title: 'Monitoring',
                                content: "Monitoring progress to ensure successful outcomes.",
                                bg_color: "#dc3545",
                            },
                        ]
                    },
                    list_faq: {
                        id: 'faq',
                        about: {
                            title: 'Frequently Asked Questions',
                            description: "Here we address common questions and uncertainties surrounding innovative strategy.",
                            price: "Free",
                            bg_color: "#5cb100",
                            url: "https://app.landing.page",
                        }
                        ,
                        content: [
                            {
                                qn: "What is a strategy innovation consultant?",
                                ans: "A strategy innovation consultant is an expert who helps organizations identify, develop, and implement innovative strategies to achieve competitive advantage, drive growth, and adapt to changing market conditions."
                            },
                            {
                                qn: "Why do companies need a strategy innovation consultant?",
                                ans: "Companies need a strategy innovation consultant to gain fresh perspectives, leverage expert knowledge, and facilitate the development of effective innovation strategies that can help them stay competitive and capitalize on new opportunities."
                            },
                            {
                                qn: "How can a strategy innovation consultant help improve business performance?",
                                ans: "A strategy innovation consultant can help improve business performance by identifying inefficiencies, recommending innovative solutions, optimizing processes, and aligning the organization's strategic goals with market trends and customer needs."
                            },
                            {
                                qn: "What types of businesses benefit the most from innovation consulting?",
                                ans: "Businesses across all industries can benefit from innovation consulting, but those in highly competitive, rapidly changing, or technology-driven sectors often see the most significant impact."
                            },
                            {
                                qn: "What are the key outcomes of working with a strategy innovation consultant?",
                                ans: "Key outcomes include the development of a clear innovation strategy, improved processes, enhanced product and service offerings, increased market share, and a stronger organizational culture of innovation."
                            },
                            {
                                qn: "How does a strategy innovation consultant approach problem-solving?",
                                ans: "A strategy innovation consultant approaches problem-solving through a systematic process that includes assessing the current state, conducting market and trend analysis, facilitating ideation sessions, developing strategies, and supporting implementation and monitoring."
                            },
                            {
                                qn: "Can a strategy innovation consultant help with digital transformation?",
                                ans: "Yes, a strategy innovation consultant can play a crucial role in digital transformation by identifying relevant technologies, designing digital strategies, and guiding the organization through the implementation of digital initiatives."
                            },
                            {
                                qn: "What are the signs that a company needs a strategy innovation consultant?",
                                ans: "Signs include stagnating growth, lack of new ideas, difficulty adapting to market changes, internal resistance to change, and the need to stay ahead of competitors."
                            },
                            {
                                qn: "How do strategy innovation consultants stay current with industry trends?",
                                ans: "Strategy innovation consultants stay current by continuously researching industry trends, attending conferences, participating in professional networks, and leveraging their extensive experience across various industries."
                            },
                            {
                                qn: "What should companies look for when hiring a strategy innovation consultant?",
                                ans: "Companies should look for a consultant with a proven track record, relevant industry experience, strong problem-solving skills, the ability to foster collaboration, and a strategic mindset that aligns with the company's goals and values."
                            },
                        ]

                    },
                    about: {
                        menu: [
                            { title: "Education", id: "edu" },
                            { title: "Teaching", id: "teach" },
                            { title: "Work Experience", id: "exp" },
                            { title: "Technical Skills", id: "skill" },
                        ],
                    },
                    contact: {
                        id: 'contact',
                        title: 'Contact',
                        subtitle: 'I aspire to inspire, connect, and collaborate with like-minded individuals, fostering an environment where ideas flourish and innovations thrive.',
                        content: [
                            {
                                name: "LinkedIn",
                                message: "Grow our network by connecting via LinkedIn!",
                                url: 'https://www.linkedin.com/in/eric-tk-chou/',
                                icon: 'fa-brands fa-linkedin-in',
                                type: 'link',
                            },
                            {
                                name: "E-mail",
                                message: "Collabs? Write me! Click to copy e-mail address.",
                                url: 'eric@withinsigma.com',
                                icon: 'fa fa-envelope',
                                type: 'mail',
                            },
                        ],
                    },
                }
            },
            fr: {
                translation: {
                    menu: [
                        { title: "Home", id: "#home", link: '/' },
                        { title: "Services", id: "#services", link: '/services' },
                        { title: "Case Studies", id: "#casestudies", link: '/casestudies' },
                        { title: "About", id: "#about", link: '/about' },
                        { title: "Contact", id: "#contact", link: '/contact' },
                    ],
                    e404: {
                        title: 'Oupsie!',
                        message: 'La page que vous recherchez n\'existe pas. Vous pouvez cliquer sur le bouton ci-dessous pour revenir à la page d\'accueil.',
                        button: 'Accueil',
                        link: '/',
                    },
                    home: {
                        title: 'Stratégie d\'Innovation',
                        message: 'Donner aux individus et aux entreprises un avantage concurrentiel en assurant un succès à long terme grâce à des innovations intentionnelles et efficaces qui s\'alignent sur leur vision d\'entreprise.'
                    },

                    list_faq: {
                        id: 'faq',
                        about: {
                            title: 'Frequently Asked Questions',
                            description: "asdbvhbdkjvsbajkvbsakdbskjsabkj",
                            price: "Free",
                            bg_color: "#5cb100",
                            url: "https://app.landing.page",
                        }
                        ,
                        content: [
                            {
                                icon: 'fa-solid fa-magnifying-glass',
                                title: 'Initial Assessment',
                                content: "Evaluate the current state of the organization and SWOT.",
                                bg_color: "#28a745",
                            },
                            {
                                icon: 'fa-solid fa-users',
                                title: 'Stakeholder',
                                content: "Understand stakeholders' perspectives, gather insights, and build support for the innovation initiative.",
                                bg_color: "#1677ff",
                            },
                            {
                                icon: 'fa-solid fa-chart-line',
                                title: "Market Analysis",
                                content: "Conduct research on market trends, emerging technologies, and competitive landscapes.",
                                bg_color: "#BE398D",
                            },
                            {
                                icon: 'fa-solid fa-brain',
                                title: "Ideation Development",
                                content: "Facilitate brainstorming sessions and workshops to generate innovative ideas and develop viable concepts that align with the organization's goals.",
                                bg_color: "#ec661f",
                            },
                            {
                                icon: 'fa-solid fa-bullseye',
                                title: "Strategy Formulation",
                                content: "Create a detailed innovation strategy, including objectives, key initiatives, resource allocation, and timelines for implementation.",
                                bg_color: "#000080",
                            },
                            {
                                icon: 'fa-solid fa-tools',
                                title: 'Implementation ',
                                content: "Assist in executing the strategy and providing ongoing support.",
                                bg_color: "#6b53ab",
                            },
                            {
                                icon: 'fa-solid fa-eye',
                                title: 'Monitoring',
                                content: "Monitoring progress to ensure successful outcomes and make necessary adjustments.",
                                bg_color: "#dc3545",
                            },
                        ]

                    },
                },
            },
        }
    });

export default i18n;